
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ScrollService {
	scrollPosition$: Subject<number> = new Subject<number>(); // Subject to emit scroll position
	scrollToTop$: Subject<void> = new Subject<void>(); // Subject to trigger scrolling to top

	// Method to handle scroll event
	handleScroll(event: Event) {
		const scrollPosition = (event.target as HTMLElement).scrollTop;
		this.scrollPosition$.next(scrollPosition);
	}

	// Method to trigger scrolling to top
	triggerScrollToTop() {
		this.scrollToTop$.next();
	}
}