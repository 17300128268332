import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
	private _webStorage?: Storage;

	setStorageType(storageType: StorageType) {
		this._webStorage = window[storageType];
	}

	public getLocal<T>(key: string): T | undefined {
		this.setStorageType('localStorage');

		return this.get<T>(key);
	}

	public getSession<T>(key: string): T | undefined {
		this.setStorageType('sessionStorage');

		return this.get<T>(key);
	}

	private get<T>(key: string): T | undefined {
		let retVal: T | undefined;
		const item = this._webStorage?.getItem(key) || '';

		try {
			retVal = JSON.parse(item);
		} catch (err) {
			console.error(err);
		}

		return retVal;
	}

	public setLocal(key: string, value: unknown): boolean {
		this.setStorageType('localStorage');

		return this.set(key, value);
	}

	public setSession(key: string, value: unknown): boolean {
		this.setStorageType('sessionStorage');

		return this.set(key, value);
	}

	private set(key: string, value: unknown): boolean {
		let retVal = true;
		let valueStr = '';

		if (value != null) {
			valueStr = JSON.stringify(value);
		}

		try {
			if (this._webStorage) {
				this._webStorage.setItem(key, valueStr);
			}
		} catch (err) {
			retVal = false;
		}

		return retVal;
	}

	remove(keys: Array<string>): boolean {
		let retVal = true;

		keys.forEach(key => {
			try {
				this._webStorage?.removeItem(key);
			} catch (err) {
				retVal = false;
			}
		});

		return retVal;
	}
}

type StorageType = 'sessionStorage' | 'localStorage';
