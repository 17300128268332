import { ActionCreator, ReducerTypes, on } from '@ngrx/store';

import * as YourHomeActions from 'src/app/modules/ngrx-store/your-home/your-home.actions';

import { HomeDetails } from 'src/app/modules/core/models/sales.model';
import { Job } from 'src/app/modules/core/models/job.model';

export interface State {
	/*
	 * homeDetails
	 */
	homeDetails?: HomeDetails | null,
	loadHomeDetailsFailure?: Error | null,
	loadHomeDetailsPending?: boolean,
	loadHomeDetailsSuccess?: boolean,
	/*
	 * jobOptions
	 */
	jobOptions?: Job | null,
	loadJobOptionsFailure?: Error | null,
	loadJobOptionsPending?: boolean,
	loadJobOptionsSuccess?: boolean,
}

export const initialState: State = {}

export function getYourHomeOns<T extends State>(): ReducerTypes<T, ActionCreator[]>[] {
	return [
		/*
		 * loadHomeDetails
		 */
		on(YourHomeActions.loadHomeDetails, (state) => ({
			...state,
			homeDetails: null,
			jobOptions: null, // Job Options are dependent on Home Details
			loadHomeDetailsFailure: null,
			loadHomeDetailsPending: true,
			loadHomeDetailsSuccess: false,
			loadJobOptionsFailure: null,
			loadJobOptionsPending: true,
			loadJobOptionsSuccess: false
		})),
		on(YourHomeActions.loadHomeDetailsFailure, (state, { error }) => ({
			...state,
			homeDetails: null,
			jobOptions: null, // Job Options are dependent on Home Details
			loadHomeDetailsFailure: error,
			loadHomeDetailsPending: false,
			loadHomeDetailsSuccess: false,
			loadJobOptionsFailure: null,
			loadJobOptionsPending: false,
			loadJobOptionsSuccess: false
		})),
		on(YourHomeActions.loadHomeDetailsSuccess, (state, { homeDetails }) => ({
			...state,
			homeDetails: homeDetails,
			loadHomeDetailsFailure: null,
			loadHomeDetailsPending: false,
			loadHomeDetailsSuccess: true
		})),
		/*
		 * loadJobOptions
		 */
		on(YourHomeActions.loadJobOptions, (state) => ({
			...state,
			jobOptions: null,
			loadJobOptionsFailure: null,
			loadJobOptionsPending: true,
			loadJobOptionsSuccess: false
		})),
		on(YourHomeActions.loadJobOptionsFailure, (state, { error }) => ({
			...state,
			jobOptions: null,
			loadJobOptionsFailure: error,
			loadJobOptionsPending: false,
			loadJobOptionsSuccess: false
		})),
		on(YourHomeActions.loadJobOptionsSuccess, (state, { jobOptions }) => ({
			...state,
			jobOptions: jobOptions,
			loadJobOptionsFailure: null,
			loadJobOptionsPending: false,
			loadJobOptionsSuccess: true
		})),
	];
}