import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * This pipe takes a string `content` as input and bypasses Angular's built-in security check
 * using the `sanitizer.bypassSecurityTrustHtml()` method. This method marks the `content` as
 * trusted HTML, allowing it to be rendered in the application without Angular's default
 * sanitization, which would otherwise strip some content, making it unrenderable.
 */
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) { }
	
	transform(content: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}
}