import { Component, Input, OnInit } from '@angular/core';

import { ScarStageService } from 'src/app/modules/construction-progress/service/scar-stage.service';
import { ConstructionStageEnum } from 'src/app/modules/core/models/sales.model';

import { YourHomeFacade } from 'src/app/modules/ngrx-store/your-home/your-home.facade';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

import { ScarBarIcons } from 'src/app/modules/shared/types/scar-bar-icons';

@Component({
	selector: 'cp-scar-progress-bar',
	templateUrl: './scar-progress-bar.component.html',
	styleUrls: ['./scar-progress-bar.component.scss']
})
export class ScarProgressBarComponent extends UnsubscribeOnDestroy implements OnInit {
	constructionStage: ConstructionStageEnum | undefined;

	closeOfEscrow: Date | undefined;

	// Whether the label should be visible
	@Input() shouldShowLabels: boolean = true;

	@Input() isClickable: boolean = false;

	scarBarIcons = ScarBarIcons;

	constructor(public yourHomeFacade: YourHomeFacade, private scarService: ScarStageService) {
		super();
	}

	ngOnInit(): void {
		this.yourHomeFacade.homeDetails$.pipe(
			this.takeUntilDestroyed()
		).subscribe((homeDetails) => {
			this.constructionStage = homeDetails?.portalStageName;
			this.closeOfEscrow = homeDetails?.lot.closeOfEscrow;

			// If a CoE data exists, then all stages are complete
			if (this.closeOfEscrow && this.isClickable) {
				this.scarService.setSelectedScarStageId(5);
			}
		})
	}
}
