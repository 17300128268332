import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export function withSpinner(http: HttpClient): HttpClient {
	//check for mocked http - if it's mocked, just pass through the mocked instance
	if (http.request === undefined) {
		return http;
	}

	type Options = {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		observe?: 'body';
		params?: HttpParams | {
			[param: string]: string | string[];
		};
		reportProgress?: boolean;
		responseType?: 'json';
		withCredentials?: boolean;
	};

	const getOptions = (options?: Options) => {
		if (options) {
			if (options.headers && (<HttpHeaders>options.headers).set !== undefined) {
				options.headers = (<HttpHeaders>options.headers).set('X-SHOW-SPINNER', '');
			} else if (options.headers) {
				(<{ [header: string]: string | string[] }>options.headers)['X-SHOW-SPINNER'] = '';
			} else {
				options.headers = { 'X-SHOW-SPINNER': '' };
			}

			return options;
		} else {
			return {
				headers: { 'X-SHOW-SPINNER': '' }
			};
		}
	};

	return <HttpClient>{
		get: (url: string, options?: Options) => {
			const newOptions = getOptions(options);

			return <Observable<unknown>>HttpClient.prototype.get.apply(http, [url, newOptions]);
		},

		put: (url: string, body: unknown, options?: Options) => {
			const newOptions = getOptions(options);

			return <Observable<unknown>>HttpClient.prototype.put.apply(http, [url, body, newOptions]);
		},

		post: (url: string, body: unknown, options?: Options) => {
			const newOptions = getOptions(options);

			return <Observable<unknown>>HttpClient.prototype.post.apply(http, [url, body, newOptions]);
		},

		patch: (url: string, body: unknown, options?: Options) => {
			const newOptions = getOptions(options);

			return <Observable<unknown>>HttpClient.prototype.patch.apply(http, [url, body, newOptions]);
		},

		delete: (url: string, options?: Options) => {
			const newOptions = getOptions(options);

			return <Observable<unknown>>HttpClient.prototype.delete.apply(http, [url, newOptions]);
		}
	};
}
