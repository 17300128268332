import * as pulteConfig from 'src/brands/pulte.json';
import * as centexConfig from 'src/brands/centex.json';
import * as delWebbConfig from 'src/brands/del-webb.json';
import * as divostaConfig from 'src/brands/divosta.json';
import * as americanWestConfig from 'src/brands/american-west.json';
import * as johnWielandConfig from 'src/brands/john-wieland.json';

export type WcagItem = {
	[background: string]: {
		aa: boolean,
		aaa: boolean
	}
}

export type BrandMapItem = {
	name: string;
	secondLevelDomain: string;
	title: string;
	warrantyUrl: string;
	hoaInfoUrl: string;
	guideUrl: string;
	config: {
		accessibility: {
			[foreground: string]: WcagItem;
		}
		images: {
			[key: string]: string;
		}
		designPreviewImageUrls: string[];
		styles: {
			[key: string]: string;
		},
	};
}

export const BrandMap: BrandMapItem[] = [
	{
		name: 'pulte',
		secondLevelDomain: 'pulte',
		title: 'Pulte',
		warrantyUrl: 'https://www.pulte.com/build/10-year-warranty#fullwarranty',
		hoaInfoUrl: 'https://www.pulte.com/my-pulte-account/owners-entry/hoa-information',
		guideUrl: 'https://www.pulte.com/new-construction-homebuying-guide',
		config: pulteConfig,
	}, 
	{
		name: 'centex',
		secondLevelDomain: 'centex',
		title: 'Centex',
		warrantyUrl: 'https://www.centex.com/build/10-year-warranty#fullwarranty',
		hoaInfoUrl: 'https://www.centex.com/my-centex-account/owners-entry/hoa-information',
		guideUrl: 'https://www.centex.com/new-construction-homebuying-guide',
		config: centexConfig,
	},
	{
		name: 'delWebb',
		secondLevelDomain: 'delwebb',
		title: 'Del Webb',
		warrantyUrl: 'https://www.delwebb.com/best-retirement-living/best-home-builders/10-year-warranty',
		hoaInfoUrl: 'https://www.delwebb.com/my-del-webb-account/owners-entry/hoa-information',
		guideUrl: 'https://www.delwebb.com/new-construction-homebuying-guide',
		config: delWebbConfig,
	},
	{
		name: 'divosta',
		secondLevelDomain: 'divosta',
		title: 'DiVosta',
		warrantyUrl: 'https://www.divosta.com/build/10-year-warranty#fullwarranty',
		hoaInfoUrl: 'https://www.divosta.com/my-divosta-account/owners-entry/hoa-information',
		guideUrl: 'https://www.divosta.com/new-construction-homebuying-guide',
		config: divostaConfig,
	},
	{
		name: 'americanWest',
		secondLevelDomain: 'americanwesthomes',
		title: 'American West',
		warrantyUrl: 'https://www.americanwesthomes.com/the-american-west-difference/award-winning-quality/american-west-home-warranty',
		hoaInfoUrl: 'https://www.americanwesthomes.com/my-american-west-account/owners-entry/homeowners-association',
		guideUrl: 'https://www.americanwesthomes.com/new-construction-homebuying-guide',
		config: americanWestConfig,
	}, {
		name: 'johnWieland',
		secondLevelDomain:'jwhomes',
		title: 'John Wieland',
		warrantyUrl: 'https://www.jwhomes.com/the-john-wieland-difference/award-winning-quality/john-wieland-home-warranty',
		hoaInfoUrl: 'https://www.jwhomes.com/my-jw-account/owners-entry/homeowners-association',
		guideUrl: 'https://www.jwhomes.com/new-construction-homebuying-guide',
		config: johnWielandConfig,
	}
];

// Pulte is the default brand. This is used for if the community does not return with a brand for some reason.
export const DEFAULT_BRAND: BrandMapItem = BrandMap[0];
export const DEFAULT_BRAND_PRIMARY_WCAG = BrandMap[0]['config']['accessibility']['primary'];
export const DEFAULT_BRAND_SECONDARY_WCAG = BrandMap[0]['config']['accessibility']['secondary'];
