import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';

import { NAVIGATION_ROUTES } from 'src/app/modules/shared/constants/route-paths';

@Component({
	selector: 'cp-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {
	errorCode = '404';
	routes = NAVIGATION_ROUTES;

	constructor(private appFacade: AppFacade) {}

	ngOnInit(): void {
		this.appFacade.setDisclaimers([]);
	}

	ngOnDestroy(): void {
		this.appFacade.resetDisclaimers();
	}
}