import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { IEnvironment } from 'src/environments/environment.model';

@Injectable()
export class SettingsService {
	private settings?: IEnvironment;
	private settings$: Observable<IEnvironment>;

	constructor() {
		this.settings$ = from(fetch('./assets/environment/settings.json').then(resp => resp.json())).pipe(
			map(response => <IEnvironment>response)
		);
	}

	public getSettings(): Observable<IEnvironment> {
		if (this.settings) {
			return of(this.settings);
		}

		return this.settings$.pipe(share());
	}
}