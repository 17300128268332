import { Identity } from 'src/app/modules/core/models/shr.model';

export interface HomeDetails {
	financialCommunity: FinancialCommunity;
	jobId: number;
	portalStageName: ConstructionStageEnum;
	lot: Lot;
	planCommunity: PlanCommunity;
	salesAgreementId: number;
	elevationImage?: string;
	salesAgreementNumber?: string;
	identity: Identity;
}

export enum ConstructionStageEnum {
	Introduction = 1,
	Excavation = 2,
	Frame = 3,
	Drywall = 4,
	Complete = 5
}

export enum MasterTaskNameEnum {
	Introduction = 1,
	Start = 2,
	Frame = 3,
	Second = 4,
	Final = 5,
}

export interface FinancialCommunity {
	id: number;
	isDesignPreviewEnabled: boolean;
	name: string;
	market: Market;
}

export interface Market {
	id: number;
	name: string;
}

export interface Lot {
	id: number;
	city: string;
	lotBlock: string;
	closeOfEscrow: Date;
	lotHandingAssocs: LotHandingAssoc[];
	postalCode: string;
	stateProvince: string;
	streetAddress1: string;
	streetAddress2: string;
}

export interface LotHandingAssoc {
	handing: string;
	handingId: number;
	lot: string;
	lotId: number;
}

export interface PlanCommunity {
	id: number;
	financialPlanIntegrationKey: string;
	masterPlanNumber: string;
	planSalesName: string;
}