<div class="cp-scar-progress-bar-container" [class.cp-label-hidden]="!shouldShowLabels">
	<ng-container *ngFor="let scarIcon of scarBarIcons; let i = index">
		<cp-scar-progress-icon
			[label]="scarIcon.label"
			[svgIcon]="scarIcon.svgIcon"
			[matIcon]="scarIcon.matIcon"
			[constructionStage]="constructionStage"
			[closeOfEscrow]="closeOfEscrow"
			[step]="i + 1"
			[shouldShowLabel]="shouldShowLabels"
			[isClickable]="isClickable">
		</cp-scar-progress-icon>
	</ng-container>
</div>