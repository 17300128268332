import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

import { OAuthStorage } from 'angular-oauth2-oidc';

import { finalize } from 'rxjs';

import { SpinnerService } from 'src/app/modules/core/services/spinner.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(@Inject(forwardRef(() => OAuthStorage)) private authStorage: OAuthStorage,
		@Inject(forwardRef(() => SpinnerService)) private spinnerService: SpinnerService) { }

	intercept(req: HttpRequest<unknown>, next: HttpHandler) {

		if (req.headers.has('X-SHOW-SPINNER')) {
			// This forces parent component to show spinner in case it isn't ready
			setTimeout(() => {
				this.spinnerService.showSpinner(true);
			});

			const authReq = req.clone({
				headers: req.headers.delete('X-SHOW-SPINNER').set('Authorization', 'Bearer ' + this.authStorage.getItem('id_token'))
			});

			return next.handle(authReq).pipe(
				finalize(() => {
					this.spinnerService.showSpinner(false);
				}));
		} else {
			const authReq = req.clone({
				headers: req.headers.set('Authorization', 'Bearer ' + this.authStorage.getItem('id_token'))
			});

			return next.handle(authReq);
		}
	}
}
