/* eslint-disable @typescript-eslint/no-explicit-any */
import { INIT, ActionReducer, MetaReducer } from '@ngrx/store';

import { StorageService } from 'src/app/modules/core/services/storage.service';

import * as fromRoot from 'src/app/modules/ngrx-store/reducers';

export class RehydrateMap {
	private static featureMap: { [feature: string]: (state: any) => any } = {};

	static onRehydrate<TState>(feature: string, fn: (state: TState) => TState) {
		this.featureMap[feature] = fn;
	}

	static rehydrate(state: any): any {
		if (state) {
			for (const feature of Object.keys(state)) {
				const featureState = state[feature];
				if (this.featureMap[feature]) {
					state[feature] = this.featureMap[feature](featureState);
				}
			}
			return state;
		}
		return null;
	}
}

export function sessionStateReducerFactory(storageService: StorageService): MetaReducer<fromRoot.State> {
	return (reducer: ActionReducer<any>) => (state, action) => {
		if (action.type === INIT) {
			const rehydratedState = storageService.getSession<string>('cp_state');
			const newState = Object.assign({}, state, RehydrateMap.rehydrate(rehydratedState));
			return newState;
		}

		const nextState = reducer(state, action);
		try {
			storageService.setSession('cp_state', nextState);
		} catch (e) {
			console.error(e);
		}

		return nextState;
	};
}
