import { Component, EventEmitter, Input, Output } from '@angular/core';

import { keyPressHandler } from 'src/app/modules/shared/utilities/accessibility.utils';

@Component({
	selector: 'cp-cta-card',
	templateUrl: './cta-card.component.html',
	styleUrls: ['./cta-card.component.scss']
})
export class CtaCardComponent {
	keyPressHandler = keyPressHandler;

	@Input() header = '';

	@Input() content = '';

	@Input() buttonLabel = '';

	@Input() ctaTelemetryLabel?: string;

	@Input() images: string[] = [];

	@Input() imageCaptions: string[] = [];

	@Input() externalButtonLink?: string;

	@Input() internalButtonLink?: string;

	@Input() shiftImages = false;
	
	@Input() buttonColorPrimary = false;

	@Input() cardAriaLabel?: string;
	
	@Input() iconAriaLabel?: string;

	@Output() buttonClick = new EventEmitter();
}