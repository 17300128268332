import { createAction, props } from '@ngrx/store';

import { UserInfo } from 'src/app/modules/shared/types/auth';

const source = '[App]';

/*
 * setUserInfo
 */
export const setUserInfo = createAction(
	`${source} setUserInfo`,
	props<{ userInfo: UserInfo }>()
);

/*
 * setDisclaimers
 */
export const setDisclaimers = createAction(
	`${source} setDisclaimers`,
	props<{ disclaimers: string[] }>()
);