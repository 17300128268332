const YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365; // 100ms * 60secs * 60mins * 24hrs * 365 days

export const dateDifInYears = (firstDate: Date, secondDate: Date) => (firstDate.getTime() - secondDate.getTime()) / YEAR_IN_MS;

export function calculateTenYearsAndDaysSince(date: Date): boolean {
	const closeOfEscrowDate = new Date(date);
	const now = new Date();
	const timeSpan = Math.abs(dateDifInYears(now, closeOfEscrowDate));
	const isTenYearsPostClose = timeSpan > 10; // 10 years and 1 day or more

	return  isTenYearsPostClose;
}