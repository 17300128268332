import { createFeatureSelector, createSelector } from '@ngrx/store';

import { APP_DOMAIN_FEATURE_KEY, State } from 'src/app/modules/ngrx-store/reducers';

import { dateDifInYears } from 'src/app/modules/shared/utilities/date.util';
  
export const getAppDomainState = createFeatureSelector<State>(
	APP_DOMAIN_FEATURE_KEY
);

export const preCloseOrPostCloseWithUpdates = createSelector(
	getAppDomainState,
	(state: State) => {
		const coeDate = state.homeDetails?.lot.closeOfEscrow;

		if (coeDate) {
			return dateDifInYears(new Date(), new Date(coeDate)) <= 1 ? !!state.constructionUpdates?.length : false;
		}

		return true;
	}
);