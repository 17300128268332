<div class="cp-modal">
	<div class="cp-modal-header" mat-dialog-title align="start" *ngIf="data.title">
		<h2 class="cp-modal-title">{{data.title}}</h2>
		<button mat-icon-button aria-label="close-modal" disableRipple (click)="closeDialog('close')" data-testid="close-button">
			<mat-icon fontSet="material-symbols-outlined">close</mat-icon>
		</button>
	</div>
	
	<mat-dialog-content class="cp-modal-content">
		<p class="cp-modal-text" align="{{data.contentAlignment}}">{{data.content}}</p>
	</mat-dialog-content>

	<mat-dialog-actions class="cp-modal-actions" align="center">
		<button mat-button color="accent" (click)="closeDialog('secondary')" data-testid="secondary-button" *ngIf="data.secondaryLabel">{{data.secondaryLabel}}</button>
		<button mat-button color="primary" (click)="closeDialog('primary')" data-testid="primary-button">{{data.primaryLabel}}</button>
	</mat-dialog-actions>
</div>