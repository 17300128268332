import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { HomeDetails } from 'src/app/modules/core/models/sales.model';
import { Job } from 'src/app/modules/core/models/job.model';

const source = '[YourHome]';

/*
 * loadHomeDetails
 */
export const loadHomeDetails = createAction(
	`${source} loadHomeDetails`,
	props<{ salesAgreementId?: number }>()
);

export const loadHomeDetailsFailure = createAction(
	`${source} loadHomeDetailsFailure`,
	props<{ error: HttpErrorResponse }>()
);

export const loadHomeDetailsSuccess = createAction(
	`${source} loadHomeDetailsSuccess`,
	props<{ homeDetails: HomeDetails }>()
);

/*
 * loadJobOptions
 */
export const loadJobOptions = createAction(
	`${source} loadJobOptions`,
	props<{ jobId: number }>()
);

export const loadJobOptionsFailure = createAction(
	`${source} loadJobOptionsFailure`,
	props<{ error: Error }>()
);

export const loadJobOptionsSuccess = createAction(
	`${source} loadJobOptionsSuccess`,
	props<{ jobOptions: Job }>()
);