import { createSelector } from '@ngrx/store';

import { State } from 'src/app/modules/ngrx-store/app/app.reducer';
import { getAppDomainState } from 'src/app/modules/ngrx-store/selectors';

export const getCustomerName = createSelector(
	getAppDomainState,
	(state: State) => state.customerName
);

export const getUserInfo = createSelector(
	getAppDomainState,
	(state: State) => state.userInfo
);

export const getDisclaimers = createSelector(
	getAppDomainState,
	(state: State) => state.disclaimers
);