import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromRoot from 'src/app/modules/ngrx-store/reducers';
import * as Selectors from 'src/app/modules/ngrx-store/selectors';
import { ConstructionProgressFacade } from 'src/app/modules/ngrx-store/construction-progress/construction-progress.facade';
import { ServiceRequestsFacade } from 'src/app/modules/ngrx-store/service-requests/service-requests.facade';
import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';
import { YourHomeFacade } from 'src/app/modules/ngrx-store/your-home/your-home.facade';

@Injectable()
export class RootFacade {
	preCloseOrPostCloseWithUpdates$ = this.store.pipe(select(Selectors.preCloseOrPostCloseWithUpdates));

	constructionProgressFacade: ConstructionProgressFacade;
	appFacade: AppFacade;
	yourHomeFacade: YourHomeFacade;
	serviceRequestsFacade: ServiceRequestsFacade;

	constructor(
		constructionProgressFacade: ConstructionProgressFacade,
		appFacade: AppFacade,
		yourHomeFacade: YourHomeFacade,
		serviceRequestsFacade: ServiceRequestsFacade,
		protected store: Store<fromRoot.State>
	) {
		this.constructionProgressFacade = constructionProgressFacade;
		this.appFacade = appFacade;
		this.yourHomeFacade = yourHomeFacade;
		this.serviceRequestsFacade = serviceRequestsFacade;
	}
}