import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomerPortalComponent } from 'src/app/modules/core/components/customer-portal/customer-portal.component';
import { ErrorPageComponent } from 'src/app/modules/core/components/error-page/error-page.component';
import { InvalidErrorPageComponent } from 'src/app/modules/core/components/error-page/invalid-error-page/invalid-error-page.component';

import { isLoggedInGuard } from 'src/app/modules/shared/guards/auth.guard';
import { ROUTES } from 'src/app/modules/shared/constants/route-paths';

const portalRoutes: Routes = [
	{
		path: ROUTES.Dashboard,
		loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
	},
	{
		path: ROUTES.YourHome,
		loadChildren: () => import('./modules/your-home/your-home.module').then(m => m.YourHomeModule),
	},
	{
		path: ROUTES.ConstructionProgress,
		loadChildren: () => import('./modules/construction-progress/construction-progress.module').then(m => m.ConstructionProgressModule)
	},
	{
		path: ROUTES.ServiceRequests,
		loadChildren: () => import('./modules/service-requests/service-requests.module').then(m => m.ServiceRequestsModule)
	},
	{
		path: ROUTES.FinancialServices,
		loadChildren: () => import('./modules/financial-services/financial-services.module').then(m => m.FinancialServicesModule)
	},
	{
		path: ROUTES.Resources,
		loadChildren: () => import('./modules/resources/resources.module').then(m => m.ResourcesModule)
	},
	{
		// #418853 Redirect to Dashboard page when navigating to base URL
		path: '',
		pathMatch: 'full',
		redirectTo: ROUTES.Dashboard
	},
	{
		path: '**',
		component: ErrorPageComponent
	}
];

export const errorRoutes = [
	{
		path: ROUTES.ServerError,
		component: InvalidErrorPageComponent
	},
	{
		path: ROUTES.SalesAgreementInvalid,
		component: InvalidErrorPageComponent
	},
	{
		path: '**',
		component: InvalidErrorPageComponent
	}
]

const routes: Routes = [
	// The style guide exists outside of the navigation wrapper
	{
		path: ROUTES.StyleGuide,
		loadChildren: () => import('./modules/style-guide/style-guide.module').then(m => m.StyleGuideModule)
	},
	{
		path: ROUTES.Error,
		children: errorRoutes
	},
	{
		path: `${ ROUTES.Preview }/:salesAgreementId`,
		canActivate: [isLoggedInGuard],
		component: CustomerPortalComponent,
		children: portalRoutes,
	},
	// All child components defined in this object will be wrapped by the side-menu and header
	{
		path: ROUTES.CustomerPortal,
		canActivate: [isLoggedInGuard],
		component: CustomerPortalComponent,
		children: portalRoutes
	},
	{
		path: '**',
		redirectTo: ROUTES.CustomerPortal
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		paramsInheritanceStrategy: 'always',
		bindToComponentInputs: true
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }