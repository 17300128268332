import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Observable, Subject } from 'rxjs';

import { BrandMap, BrandMapItem, DEFAULT_BRAND } from 'src/app/modules/shared/types/brand';
import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { IEnvironment } from 'src/environments/environment.model';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Injectable({
	providedIn: 'root'
})
export class BrandService extends UnsubscribeOnDestroy {
	private currentBrandSubject: Subject<BrandMapItem> = new Subject<BrandMapItem>(); // So that you can't assign value to brand from outside the service

	brand: BrandMapItem = DEFAULT_BRAND;
	currentBrand$: Observable<BrandMapItem> = this.currentBrandSubject;

	private settings?: IEnvironment;

	constructor(settingsService: SettingsService,
		private title: Title) {
		super();
		// Brand domains are defined in settings.json
		// Settings values are replaced during deployment per environment
		settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			this.settings = settings;
			this.setBrandFromDomain();
		});
	}

	applyBrandTheme(): void {
		// Overwrite scss variables based on brand
		if (this.brand && typeof document !== 'undefined') {
			Object.keys(this.brand.config.styles)
				.forEach(key => {
					document.documentElement.style.setProperty(
						`--${key}`, (this.brand?.config.styles as { [key: string]: string })[key]
					);
				});
		}

		const favicon = document.getElementById('favicon-id');
		favicon?.setAttribute('href', this.brand.config.images['favicon']);

		this.title.setTitle(`${this.brand.title} Portal`);
	}

	getBrand(): BrandMapItem {
		return this.brand;
	}

	getBrandImage(): string {
		return this.brand.config.images['logo'];
	}

	getBrandDesignPreviewImages() : string[] {
		return this.brand.config.designPreviewImageUrls;
	}

	setBrand(brand: BrandMapItem): void {
		this.brand = brand;
		this.currentBrandSubject.next(this.brand);
		this.applyBrandTheme();
	}

	// sets brand based on domain. if none found it uses default
	setBrandFromDomain(): void {
		let brand = DEFAULT_BRAND;

		if (this.settings) {
			const host = window.location.host;
			Object.keys(this.settings.brandConfigs)
				.forEach(key => {
					if (host === (this.settings?.brandConfigs[key].url)) {
						brand = BrandMap.find(brand => brand.name === key) ?? DEFAULT_BRAND;
					}
				});
		}

		this.setBrand(brand);
	}
}
