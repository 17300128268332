import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ScarStageService {
	// SCAR stageId 3 "Frame" is the default stage.
	private selectedScarStageIdSubject = new BehaviorSubject<number>(3);
	selectedScarStageId$ = this.selectedScarStageIdSubject.asObservable();

	setSelectedScarStageId(stageId: number) {
		this.selectedScarStageIdSubject.next(stageId);
	}
}
