import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin, ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

import { IEnvironment } from 'src/environments/environment.model';

import { HomeDetails } from 'src/app/modules/core/models/sales.model';

import { UserInfo } from 'src/app/modules/shared/types/auth';

import { CORE_ROUTES } from 'src/app/modules/shared/constants/route-paths';

@Injectable({
	providedIn: 'root'
})
export class ApplicationInsightsService {
	// Angular App Insights examples here: https://github.com/microsoft/applicationinsights-angularplugin-js
	myinjector = Injector.create({
		providers: [
			{ provide: ApplicationinsightsAngularpluginErrorService, useClass: ApplicationinsightsAngularpluginErrorService }
		]
	});

	private angularPlugin = new AngularPlugin(this.myinjector);
	private clickPluginInstance = new ClickAnalyticsPlugin();
	private clickPluginConfig = {
		autoCapture: true,
		dataTags: { useDefaultContentNameOrId: true }
	};

	private appInsights?: ApplicationInsights;

	constructor(private router: Router) {}

	initializeTelemetry(brandName: string, homeDetails: HomeDetails, settings: IEnvironment, userInfo: UserInfo) {
		if (settings.envName != 'local') {
			this.appInsights = new ApplicationInsights({
				config: {
					autoTrackPageVisitTime: true,
					connectionString: settings.applicationInsights.connectionString,
					// Disables remote dependency logging
					disableAjaxTracking: true,
					enableAutoRouteTracking: true,
					extensions: [this.angularPlugin, this.clickPluginInstance],
					extensionConfig: {
						[this.angularPlugin.identifier]: {
							router: this.router, useInjector: true
						},
						[this.clickPluginInstance.identifier]: this.clickPluginConfig
					},
				},
			});
			this.appInsights.loadAppInsights();
			this.appInsights.setAuthenticatedUserContext(userInfo.userName, userInfo.accountId, true);
			this.appInsights.addTelemetryInitializer(envelope => {
				if (envelope.baseData && envelope.baseType === 'PageviewData') {
					envelope.baseData['name'] = `${brandName} Portal`;
				}

				envelope.data = {
					isPreview: window.location.pathname.includes(CORE_ROUTES.Preview),
					currentPage: window.location.pathname.split('/').at(-1),
					financialCommunityName: homeDetails.financialCommunity.name,
					marketName: homeDetails.financialCommunity.market.name,
					brandName: brandName,
					salesAgreementNumber: homeDetails.salesAgreementNumber
				}
			});
		}
	}
}