type ScarBarIcon = {
	label: string;
	svgIcon: string;
	matIcon: string;
	step: number;
};

export const ScarBarIcons: ScarBarIcon[] = [
	{
		label: 'Introduction',
		svgIcon: 'scar_introduction',
		matIcon: '',
		step: 1
	},
	{
		label: 'Foundation',
		svgIcon: 'scar_foundation',
		matIcon: '',
		step: 2
	},
	{
		label: 'Frame',
		svgIcon: '',
		matIcon: 'foundation',
		step: 3
	},
	{
		label: 'Drywall',
		svgIcon: 'scar_drywall',
		matIcon: '',
		step: 4
	},
	{
		label: 'Complete',
		svgIcon: 'scar_complete',
		matIcon: '',
		step: 5
	}
];
