import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IEnvironment } from 'src/environments/environment.model';
import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { Job } from 'src/app/modules/core/models/job.model';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Injectable({
	providedIn: 'root'
})
export class JobService extends UnsubscribeOnDestroy {	
	private settings!: IEnvironment;

	constructor(private _http: HttpClient,
		settingsService: SettingsService) {
		super();

		settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			this.settings = settings;
		});
	}

	getJobOptions(jobId: number): Observable<Job> {
		const url = `${this.settings?.apiUrl}job/${jobId}/options`;
		return this._http.get<Job>(url);
	}
}