import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ShrService } from 'src/app/modules/core/services/shr.service';

import * as ConstructionProgressActions from 'src/app/modules/ngrx-store/construction-progress/construction-progress.actions';
import * as YourHomeActions from 'src/app/modules/ngrx-store/your-home/your-home.actions';
import { getCloseOfEscrow, getSalesAgreementId } from 'src/app/modules/ngrx-store/your-home/your-home.selectors';

import { dateDifInYears } from 'src/app/modules/shared/utilities/date.util';
import { displayError } from 'src/app/modules/shared/utilities/notifications.utils';

@Injectable()
export class ConstructionProgressEffects {

	constructor(
		private actions$: Actions,
		private shrService: ShrService,
		private store: Store
	) { }

	loadConstructionUpdates$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetailsSuccess, ConstructionProgressActions.loadConstructionUpdates),
		withLatestFrom(this.store.select(getSalesAgreementId)),
		map(([, sagId]) => sagId),
		filter(Boolean), // Checks to make sure sagId is not undefined
		withLatestFrom(this.store.select(getCloseOfEscrow)),
		map(([sagId, closeOfEscrow]) => ({ sagId, closeOfEscrow })),
		filter(x => !x.closeOfEscrow || dateDifInYears(new Date(), new Date(x.closeOfEscrow)) <= 1), // Checks to make sure closeOfEscrow is either undefined or within 1 year from today
		switchMap(x =>
			this.shrService.getConstructionUpdates(x.sagId).pipe(
				map((constructionUpdates) => ConstructionProgressActions.loadConstructionUpdatesSuccess({ constructionUpdates })),
				catchError((err) => of(ConstructionProgressActions.loadConstructionUpdatesFailure({ error: err })))
			))
	));

	// When loading Construction Updates fails, display an alert notifying the user of the error
	loadConstructionUpdatesFailure$ = createEffect(() => this.actions$.pipe(
		ofType(ConstructionProgressActions.loadConstructionUpdatesFailure),
		tap(err => {
			displayError(err?.error);
		})
	),
	{ dispatch: false }
	);
}