import { Component, ElementRef, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'cp-text-card',
	templateUrl: './text-card.component.html',
	styleUrls: ['./text-card.component.scss']
})
export class TextCardComponent {
	@Input() header: string = '';

	@Input({ required: true }) template!: TemplateRef<ElementRef>;
}
