/* eslint-disable no-unused-vars */
import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

import { Breakpoints, Size } from 'src/app/modules/shared/types/breakpoints';

@Directive({
	selector: '[cpIfViewSize]'
})
export class IfViewSizeDirective implements OnDestroy {
	private subscription: Subscription = new Subscription();

	@Input() set cpIfViewSize(value: Size | Size[]) {
		let values: string[]  = [];

		if (typeof value === 'string') {
			values = [Breakpoints[value]];
		} else if (value instanceof Array) {
			value.forEach(v => {
				values.push(Breakpoints[v]);
			});
		}

		this.subscription.unsubscribe();
		this.subscription = this.breakpointObserver
			.observe(values)
			.subscribe(res => {
				this.updateView(res)
			});
	}

	constructor(
		private breakpointObserver: BreakpointObserver,
		private viewContainer: ViewContainerRef,
		private templateRef: TemplateRef<unknown>
	) {}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	updateView({ matches }: BreakpointState) {
		if (matches && !this.viewContainer?.length) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}	else if (!matches && this.viewContainer?.length) {
			this.viewContainer.clear();
		}
	}
}
