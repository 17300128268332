import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { META_REDUCERS, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { StorageService } from 'src/app/modules/core/services/storage.service';

import { ConstructionProgressEffects } from 'src/app/modules/ngrx-store/construction-progress/construction-progress.effects';
import { ConstructionProgressFacade } from 'src/app/modules/ngrx-store/construction-progress/construction-progress.facade';
import { ServiceRequestsFacade } from 'src/app/modules/ngrx-store/service-requests/service-requests.facade';
import { ServiceRequestsEffects } from 'src/app/modules/ngrx-store/service-requests/service-requests.effects';
import { sessionStateReducerFactory } from 'src/app/modules/ngrx-store/session-storage';
import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';
import { YourHomeEffects } from 'src/app/modules/ngrx-store/your-home/your-home.effects';
import { YourHomeFacade } from 'src/app/modules/ngrx-store/your-home/your-home.facade';

import * as fromRoot from 'src/app/modules/ngrx-store/reducers';
import { RootFacade } from 'src/app/modules/ngrx-store/facade';

@NgModule({
	imports: [
		StoreModule.forRoot({}),
		StoreModule.forFeature(
			fromRoot.APP_DOMAIN_FEATURE_KEY,
			fromRoot.reducer
		),
		EffectsModule.forRoot({}),
		EffectsModule.forFeature([
			ConstructionProgressEffects,
			YourHomeEffects,
			ServiceRequestsEffects
		]),
		StoreDevtoolsModule.instrument({
			name: 'CP DevTools',
			logOnly: false
		}),
	],
	providers: [
		AppFacade,
		ConstructionProgressFacade,
		RootFacade,
		YourHomeFacade,
		ServiceRequestsFacade,
		{
			provide: META_REDUCERS,
			deps: [StorageService],
			useFactory: sessionStateReducerFactory,
			multi: true
		}
	]
})

export class NgrxStoreModule {}