<div class="cp-modal">
	<div class="cp-modal-header" mat-dialog-title align="start">
		<h2>Welcome!</h2>
	</div>
	
	<mat-dialog-content class="cp-modal-content">
		<h3>{{brandName}} Portal Terms of Use</h3>
		<p>This site, {{brandName}} Portal, is available as a courtesy to you, 
			our customer, to provide you with information about your new home, 
			updates regarding construction progress and tips on how to care for your home. 
			By proceeding to view or use this site, you accept the terms below and our Website Terms of Use. 
			You may review {{brandName}}'s Website Terms of Use by clicking
			<a [href]="termsOfUseUrl" target="_blank">here</a>.
		</p>
		<ul>
			<li *ngFor="let point of bulletPoints">{{point}}</li>
		</ul>
	</mat-dialog-content>

	<mat-checkbox [disableRipple]="true" class="cp-checkbox" [(ngModel)]="agreedToTerms" data-testid="checkbox">
		I have read and agree to the <a [href]="termsOfUseUrl" target="_blank">Terms of Use</a>
	</mat-checkbox>

	<mat-dialog-actions class="cp-modal-actions" align="center">
		<button mat-button color="primary" data-testid="accept-button" [disabled]="!agreedToTerms" (click)="agreeToTerms()">I Accept</button>
	</mat-dialog-actions>
</div>