<cp-header class="cp-main-header" [isMyAccountEnabled]="false"></cp-header>
<div class="cp-flex-container">
	<div class="cp-flex-row">
		<div class="cp-flex-col">
			<h1>{{errorHeader}}</h1>
		</div>
	</div>
	<div class="cp-flex-row">
		<div class="cp-flex-col cp-error-text-container">
			<p class="cp-error-text" [innerHTML]="errorText"></p>
		</div>
	</div>
</div>