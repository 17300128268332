import { Pipe, PipeTransform } from '@angular/core';
import { HANDING_LABELS } from 'src/app/modules/shared/constants/handings';

// Converts a Handing ID into its corresponding Left/Right/NA label
@Pipe({ name: 'handingLabel' })
export class HandingLabelPipe implements PipeTransform  {
	transform = (id: number | undefined) => {
		switch (id) {
			case 1:
				return HANDING_LABELS.LEFT;
			case 2:
				return HANDING_LABELS.RIGHT;
			case 3:
			default:
				return HANDING_LABELS.NA;
		}
	}
}