import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type ModalData = {
	message: string;
}

@Component({
	selector: 'cp-error-modal',
	templateUrl: './error-modal.component.html',
	styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {

	constructor(
		public dialogRef: MatDialogRef<ErrorModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ModalData
	) {
		dialogRef.disableClose = true; // Disable closing the dialog by clicking outside of it
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
