<div *ngIf="vid; else imageTemplate"
	class="cp-video-container"
	(mouseenter)="mouseOverHandler(true)"
	(mouseleave)="mouseOverHandler(false)">
	<video #videoElement
		class="cp-cloudinary-media"
		playsInline
		[ngClass]="{ 'cp-clickable' : isClickable }"
		[src]="vid.toURL()"
		[controls]="showControls">
	</video>
	<div
		*ngIf="((!isTouchDevice || disablePlayback) && (videoElement.paused || (!videoElement.paused && isOverlayHovered)))"
		class="cp-overlay-icon-container"
		(click)="overlayClick()"
		(keypress)="keyPressHandler($event, overlayClick())"
		tabindex="1">
		<mat-icon *ngIf="videoElement.paused" fontSet="material-symbols-outlined" class="cp-play-pause-overlay-icon cp-font-fill">play_arrow</mat-icon>
		<mat-icon *ngIf="!videoElement.paused && isOverlayHovered" fontSet="material-symbols-outlined" class="cp-play-pause-overlay-icon cp-font-fill">pause</mat-icon>
	</div>
</div>
<ng-template #imageTemplate>
	<img class="cp-cloudinary-media" [src]="img?.toURL()" [alt]="altText" [ngClass]="{ 'cp-clickable' : isClickable }"/>
</ng-template>