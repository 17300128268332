// The image formats taken form the cloudinary website as supported upload formats: https://cloudinary.com/documentation/image_transformations#supported_image_formats
export const SUPPORTED_IMAGE_EXTENSIONS = [
	'ai',
	'avif',
	'gif',
	'png',
	'webp',
	'bmp',
	'bw',
	'djvu',
	'dng',
	'ps',
	'ept',
	'eps',
	'eps3',
	'fbx',
	'flif',
	'glb',
	'gltf',
	'heif',
	'heic',
	'ico',
	'indd',
	'jpg',
	'jpe',
	'jpeg',
	'jp2',
	'wdp',
	'jxl',
	'obj',
	'pdf',
	'ply',
	'psd',
	'arw',
	'cr2',
	'svg',
	'tga',
	'tif',
	'tiff',
	'u3ma',
	'usdz'
];

// These formats taken from the cloudinary website as supported upload formats: https://cloudinary.com/documentation/video_manipulation_and_delivery#supported_video_formats
export const SUPPORTED_VIDEO_EXTENSIONS = [
	'3g2',
	'3gp',
	'avi',
	'fiv',
	'm3u8',
	'ts',
	'm2ts',
	'mts',
	'mov',
	'mkv',
	'mp4',
	'mpeg',
	'mpd',
	'mxf',
	'ogv',
	'webm',
	'wmv'
];