<div class="cp-modal">
	<div class="cp-modal-header" mat-dialog-title align="start">
		<h2 class="cp-modal-title">Uh oh! Something went wrong.</h2>
		<button mat-icon-button
			(click)="closeDialog()"
			data-id="Error Modal Closed"
			aria-label="close-modal"
			data-testid="close-button"
			disableRipple>
			<mat-icon fontSet="material-symbols-outlined">close</mat-icon>
		</button>
	</div>
	
	<mat-dialog-content class="cp-modal-content">
		<p>{{data.message}}</p>
	</mat-dialog-content>
</div>