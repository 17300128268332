<nav class="cp-navigation" aria-label="Page Selection Menu">
	<div *cpIfViewSize="'desktop'" class="cp-greeting">
		<h2 *ngIf="(userInfo$ | async) !== null" data-testid="greeting-welcome">Welcome, {{ (userInfo$ | async)?.firstName }}</h2>
		<span *ngIf="(homeDetails$ | async)?.lot" data-testid="greeting-address">
			{{(homeDetails$ | async)?.lot?.streetAddress1?.trim()}},{{(homeDetails$ | async)?.lot?.streetAddress2?.trim() ? ' ' + (homeDetails$ | async)?.lot?.streetAddress2?.trim() + ',' : ''}}
			<br/>
			{{(homeDetails$ | async)?.lot?.city?.trim()}}, {{(homeDetails$ | async)?.lot?.stateProvince?.trim()}} {{(homeDetails$ | async)?.lot?.postalCode?.trim()}}
		</span>
	</div>
	<mat-list class="cp-navigation-list">
		<!-- Desktop and Non Collapsed View -->
		<ng-container *ngIf="navigationItems.length <= 5; else expandedView">
			<ng-container *ngTemplateOutlet="navigation"></ng-container>
		</ng-container>
		
		<!-- Expanded View if > 5 menu options -->
		<ng-template #expandedView>
			<!-- Mobile Views -->
			<ng-container *cpIfViewSize="'mobile'">
				<!-- First Four Navigation Items -->
				<ng-container *ngFor="let item of navigationItems | slice:0:4;">
					<mat-list-item class="cp-navigation-item" [routerLink]="item.routerLink" routerLinkActive="cp-navigation-item-selected" tabindex="-1" [attr.data-testid]="item.routerLink + '-link'">
						<div class="cp-navigation-item-content">
							<a
								class="cp-navigation-link"
								(keypress)="keyPressHandler($event, changeRoute(item.routerLink))"
								[attr.data-id]="item.label + ' Nav Link Clicked'"
								[routerLink]="item.routerLink">
								<mat-icon
									fontSet="material-symbols-outlined"
									class="cp-navigation-icon"
									[svgIcon]="item.svg"
									[class.cp-font-fill]="isRouteActive(item.routerLink)">
									{{item.icon}}
								</mat-icon>
								{{item.label}}
							</a>
						</div>
					</mat-list-item>
				</ng-container>
				<!-- More Tile -->
				<mat-list-item class="cp-navigation-item" [matMenuTriggerFor]="overflowMenu" [class.cp-navigation-item-selected]="isRouteActive('MORE')">
					<div class="cp-navigation-item-content">
						<mat-icon fontSet="material-symbols-outlined" class="cp-navigation-icon material-symbols-outlined">more_horiz</mat-icon>
						<button class="cp-navigation-link">More</button>
					</div>
				</mat-list-item>
				<mat-menu #overflowMenu="matMenu" xPosition="after" yPosition="below" overlapTrigger class="cp-overflow-menu" backdropClass="cp-overflow-menu" panelClass="cp-overflow-menu">
					<ng-container *ngTemplateOutlet="navigation"></ng-container>
					<mat-list-item class="cp-close-item">
						<div class="cp-close-content">
							<mat-icon class="material-symbols-outlined">close</mat-icon>
							<button class="cp-close-button">Close</button>
						</div>
					</mat-list-item>
				</mat-menu>
			</ng-container>
			<!-- Desktop and Tablet Views -->
			<ng-container *cpIfViewSize="['desktop', 'tablet']">
				<ng-container *ngTemplateOutlet="navigation"></ng-container>
			</ng-container>
		</ng-template>
	</mat-list>
</nav>

<!-- Navigation Items Template -->
<ng-template #navigation>
	<ng-container *ngFor="let item of navigationItems">
		<mat-list-item class="cp-navigation-item" [routerLink]="item.routerLink" routerLinkActive="cp-navigation-item-selected" tabindex="-1" [attr.data-testid]="item.routerLink + '-link'">
			<div class="cp-navigation-item-content">
				<a
					class="cp-navigation-link"
					(keypress)="keyPressHandler($event, changeRoute(item.routerLink))"
					[attr.data-id]="item.label + ' Nav Link Clicked'"
					[routerLink]="item.routerLink">
					<mat-icon
						fontSet="material-symbols-outlined"
						class="cp-navigation-icon"
						[svgIcon]="item.svg"
						[class.cp-font-fill]="isRouteActive(item.routerLink)">
						{{item.icon}}
					</mat-icon>
					{{item.label}}
				</a>
			</div>
		</mat-list-item>
	</ng-container>
</ng-template>