import { Action, createReducer } from '@ngrx/store';

import { State as ConstructionProgressState, getConstructionProgressOns, initialState as constructionProgressInitialState } from 'src/app/modules/ngrx-store/construction-progress/construction-progress.reducer';
import { State as AppState, getAppOns, initialState as appInitialState } from 'src/app/modules/ngrx-store/app/app.reducer';
import { State as YourHomeState, getYourHomeOns, initialState as yourHomeInitialState } from 'src/app/modules/ngrx-store/your-home/your-home.reducer';
import { State as ServiceRequestsState, getServiceRequestsOns, initialState as serviceRequestsInitialState } from 'src/app/modules/ngrx-store/service-requests/service-requests.reducer'

export const APP_DOMAIN_FEATURE_KEY = 'APP_DOMAIN';

export interface State extends ConstructionProgressState, AppState, YourHomeState, ServiceRequestsState { }

export interface AppDomainPartialState {
	readonly [APP_DOMAIN_FEATURE_KEY]: State;
}

export const initialState: State = {
	...constructionProgressInitialState,
	...appInitialState,
	...yourHomeInitialState,
	...serviceRequestsInitialState
};

const cpReducer = createReducer(
	initialState,
	...getConstructionProgressOns<State>(),
	...getAppOns<State>(),
	...getYourHomeOns<State>(),
	...getServiceRequestsOns<State>(),
);
  
export function reducer(state: State | undefined, action: Action) {
	return cpReducer(state, action);
}