import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

import { CORE_ROUTES } from 'src/app/modules/shared/constants/route-paths';

// Transforms an internal URL route based on whether preview mode is used
@Pipe({ name: 'internalUrl' })
export class InternalUrlPipe implements PipeTransform  {
	constructor (private router: Router) {}

	transform = (path: string) => {
		const isPreview = this.router.url.includes(CORE_ROUTES.Preview);

		// If we are not in preview mode, we can just redirect to the dashboard without SAG Number
		if (!isPreview) {
			return '/' + path;
		}

		// Segment the URL to get the preview path
		const urlSegments = this.router.url.split('/');
		const previewIndex = urlSegments.indexOf(CORE_ROUTES.Preview);
		
		// If we are in preview mode, we need to redirect to the path with SAG Number
		return urlSegments.slice(0, previewIndex + 2).join('/') + '/' + path;
	}
}