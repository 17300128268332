import { createSelector } from '@ngrx/store';

import { getAppDomainState } from 'src/app/modules/ngrx-store/selectors';
import { State } from 'src/app/modules/ngrx-store/service-requests/service-requests.reducer';

export const getServiceRequests = createSelector(
	getAppDomainState,
	(state: State) => state.serviceRequests
);

export const getServiceRequestsFailure = createSelector(
	getAppDomainState,
	(state: State) => state.loadServiceRequestsFailure
);

export const getServiceRequestsPending = createSelector(
	getAppDomainState,
	(state: State) => state.loadServiceRequestsPending
);

export const getServiceRequestsSuccess = createSelector(
	getAppDomainState,
	(state: State) => state.loadServiceRequestsSuccess
);

export const getPostServiceRequestFailure = createSelector(
	getAppDomainState,
	(state: State) => state.postServiceRequestFailure
);

export const getPostServiceRequestPending = createSelector(
	getAppDomainState,
	(state: State) => state.postServiceRequestPending
);

export const getPostServiceRequestSuccess = createSelector(
	getAppDomainState,
	(state: State) => state.postServiceRequestSuccess
);