import { Identity } from 'src/app/modules/core/models/shr.model';

export interface ServiceRequestPost {
	lotId: number;
	name: string;
	address: string;
	state: string;
	phone: string;
	email: string;
	identity: Identity | null;
	serviceRequests: ServiceRequestInfo[];
}

export interface ServiceRequestInfo {
	detailItem: ServiceRequestDetailItemEnum;
	roomItem: ServiceRequestRoomItemEnum;
	description?: string;
	attachments?: FileModel[];
}

export interface ServiceRequestResponse {
	serviceRequestId?: number;
	serviceRequestQueueItemId?: number;
	serviceRequestNumber?: string;
	submittedBy?: string;
	submittedDate: Date;
	source: string;
	status?: string;
	serviceRequestLineItems: ServiceRequestLineItemResponse[]; // When there is a serviceRequestId, this will be populated
	serviceRequestQueueItems: ServiceRequestQueueItemResponse[]; // When there is no serviceRequestId, this will be populated
}

export interface ServiceRequestLineItemResponse {
	serviceRequestId: number;
	serviceRequestLineItemId: number;
	status?: string;
	issueCode: string;
}

export interface ServiceRequestQueueItemResponse {
	serviceRequestQueueItemId: number;
	status?: string;
	subject?: string;
	description?: string;
	submittedBy?: string;
	submittedDate?: Date;
	source?: string;
	attachments: ServiceRequestQueueItemDetailAttachmentResponse[];
}

export interface ServiceRequestQueueItemDetailAttachmentResponse {
	id: number;
	serviceRequestQueueItemDetailId: number;
	filename: string;
	storageFilename: string;
	storageUri?: string;
	storageSas?: string;
}

export interface FileModel {
	name: string;
	contentType: string;
	contentBase64: string;
}

export enum ServiceRequestStatusEnum
{
	// NOTE: some of these enums may differ from their database definition, since they are transformed on the API side into different values
	Accepted = 'Accepted',
	New = 'New',
	InProgress = 'In Progress',
	OnHold = 'On Hold',
	NotWarrantable = 'Not Warrantable',
	Completed = 'Completed',
	OutOfWarranty = 'Out of Warranty',
	InformationProvided = 'Info Provided',
	RequestCancelled = 'Request Cancelled',
	Postponed = 'Postponed',
	CreatedInError = 'Created in Error',
	Closed = 'Closed',
	PendingSignOff = 'Pending Sign-Off'
}

export enum ServiceRequestSourceEnum
{
	Phone = 'Phone',
	Email = 'Email',
	Web = 'Web',
	Facebook = 'Facebook',
	Twitter = 'Twitter',
	Fax = 'Fax',
	Mail = 'Mail',
	PulteInitiatedContact = 'Pulte Initiated Contact',
	FieldContact = 'Field Contact',
	VendorInitiatedContact = 'Vendor Initiated Contact',
	WalkIn = 'Walk In',
	AutoGenerated = 'Auto Generated',
	BBB = 'BBB',
	SocialMedia = 'Social Media',
	CSMSFeedback = 'CSMS Feedback',
	IoT = 'IoT',
	Portal = 'Portal'
}

export enum ServiceRequestDetailItemEnum {
	Cabinets = 1,
	Carpet,
	Concrete,
	CounterTops,
	Doors,
	Drywall,
	Electrical,
	Exterior,
	FireplaceChimney,
	Flooring,
	Hardwood,
	HeatingCooling,
	Insulation,
	Other,
	PaintCaulk,
	Plumbing,
	RoofingGutters,
	Structure,
	Tile,
	Windows
}

export enum ServiceRequestRoomItemEnum {
	Basement = 1,
	Bathroom,
	Bedroom,
	Den,
	Dining,
	Exterior,
	FamilyRoom,
	Foyer,
	Garage,
	Kitchen,
	LaundryRoom,
	Library,
	LivingRoom,
	Nook,
	Other,
	PrimaryBath,
	PrimaryBedroom,
	SittingRoom
}

export const OpenServiceRequestStatuses = [
	ServiceRequestStatusEnum.Accepted.toString(),
	ServiceRequestStatusEnum.InProgress.toString(),
	ServiceRequestStatusEnum.New.toString(),
	ServiceRequestStatusEnum.OnHold.toString(),
	ServiceRequestStatusEnum.PendingSignOff.toString(),
];

export const ClosedServiceRequestStatuses = [
	ServiceRequestStatusEnum.Closed.toString(),
	ServiceRequestStatusEnum.Completed.toString(),
	ServiceRequestStatusEnum.CreatedInError.toString(),
	ServiceRequestStatusEnum.InformationProvided.toString(),
	ServiceRequestStatusEnum.NotWarrantable.toString(),
	ServiceRequestStatusEnum.OutOfWarranty.toString(),
	ServiceRequestStatusEnum.Postponed.toString(),
	ServiceRequestStatusEnum.RequestCancelled.toString(),
];