import { createSelector } from '@ngrx/store';

import { State } from 'src/app/modules/ngrx-store/construction-progress/construction-progress.reducer';
import { getAppDomainState } from 'src/app/modules/ngrx-store/selectors';

export const getConstructionUpdates = createSelector(
	getAppDomainState,
	(state: State) => state.constructionUpdates
);

export const getConstructionUpdatesFailure = createSelector(
	getAppDomainState,
	(state: State) => state.loadConstructionUpdatesFailure
);

export const getConstructionUpdatesPending = createSelector(
	getAppDomainState,
	(state: State) => state.loadConstructionUpdatesPending
);

export const getConstructionUpdatesSuccess = createSelector(
	getAppDomainState,
	(state: State) => state.loadConstructionUpdatesSuccess
);