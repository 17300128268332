<div class="cp-spinner-container">
	<div class="cp-spinner">
		<div class="cp-spinner-dots">
			<div class="cp-spinner-dot-01"></div>
			<div class="cp-spinner-dot-02"></div>
			<div class="cp-spinner-dot-03"></div>
			<div class="cp-spinner-dot-04"></div>
			<div class="cp-spinner-dot-05"></div>
			<div class="cp-spinner-dot-06"></div>
			<div class="cp-spinner-dot-07"></div>
			<div class="cp-spinner-dot-08"></div>
		</div>
	</div>
</div>