import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { LightboxComponent } from 'src/app/modules/construction-progress/components/lightbox/lightbox.component';

import { ConstructionUpdateAttachments } from 'src/app/modules/core/models/shr.model';
import { DialogService } from 'src/app/modules/core/services/dialog.service';

import { keyPressHandler } from 'src/app/modules/shared/utilities/accessibility.utils';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Component({
	selector: 'cp-media-grid',
	templateUrl: './media-grid.component.html',
	styleUrls: ['./media-grid.component.scss']
})
export class MediaGridComponent extends UnsubscribeOnDestroy {
	@Input() media: ConstructionUpdateAttachments[] = [];

	keyPressHandler = keyPressHandler

	lightboxRef?: MatDialogRef<unknown>;

	constructor(public dialogService: DialogService) {
		super();
	}

	openLightbox(publicId?: string) {
		if (publicId && !this.lightboxRef) {
			this.lightboxRef = this.dialogService.openDialog(LightboxComponent, {
				ariaLabelledBy: 'construction-carousel',
				data: {
					media: this.media,
					selectedMediaIndex: this.media.findIndex(m => m.cldPublicId === publicId)
				}
			});

			// Remove reference to lightbox one it has been closed
			if (this.lightboxRef) {
				this.lightboxRef.afterClosed().pipe(this.takeUntilDestroyed()).subscribe(() => this.lightboxRef = undefined);
			}
		}
	}
}